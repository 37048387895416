<template>
  <div class="container mypage withTop">
    <section class="contents Rgt detail" v-if="growthData.length > 0">
      <div class="buttonWrap">
        <button class="point" @click="handleMove()">수정</button>
        <button class="line" @click="handleDelete()">삭제</button>
      </div>
      <article class="box">
        <p>재배품목</p>
        <p>{{ growthData[0].crops }}</p>
        <p>시료</p>
        <p>{{ growthData[0].sample }}</p>
        <p>등록일</p>
        <p>{{ growthData[0].registerDate ? moment(growthData[0].registerDate).format("YYYY.MM.DD") : "-" }}</p>
        <p>초장</p>
        <p>{{ growthData[0].totalLT }} cm</p>
        <p>잎개수</p>
        <p>{{ growthData[0].leafCount }} 개</p>
        <p>잎길이</p>
        <p>{{ growthData[0].leafLength }} cm</p>
        <p>잎너비</p>
        <p>{{ growthData[0].leafWidth }} cm</p>
        <p>관부직경</p>
        <p>{{ growthData[0].crownDmt }} mm</p>
        <p>잎자루길이</p>
        <p>{{ growthData[0].ptlLt }} cm</p>
        <p>화방출뢰기</p>
        <p>{{ growthData[0].fclBuddingDt ? moment(growthData[0].fclBuddingDt).format("YYYY.MM.DD") : "-" }}</p>
        <p>개화기</p>
        <p>{{ growthData[0].blpr ? moment(growthData[0].blpr).format("YYYY.MM.DD") : "-" }}</p>
        <p>개화수</p>
        <p>{{ growthData[0].planCount }} 개</p>
        <p>착과화방</p>
        <p>{{ growthData[0].fristFclu }} 화방</p>
        <p>착과수</p>
        <p>{{ growthData[0].fristCount }} 개</p>
        <p>액아 발생기</p>
        <p>{{ growthData[0].axbdOcprd ? moment(growthData[0].axbdOcprd).format("YYYY.MM.DD") : "-" }}</p>
        <p>주당 열매수</p>
        <p>{{ growthData[0].plantFruitCount }} 개</p>
        <p>과장</p>
        <p>{{ growthData[0].fruitLt }} cm</p>
        <p>과중</p>
        <p>{{ growthData[0].flshWt }} g</p>
        <p>과폭</p>
        <p>{{ growthData[0].flshAra }} cm</p>
        <p>당도</p>
        <p>{{ growthData[0].brx }} Brix</p>
        <p>산도</p>
        <p>{{ growthData[0].acidity }} pH</p>
        <p>수확량</p>
        <p>{{ growthData[0].hvst }} kg</p>
      </article>
    </section>
  </div>
</template>
<script>
import { deleteGrowth } from "@/api/growth";
import { mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      moment: moment,
      growthId: "",
      growthData: [],
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 1);
    this.$store.dispatch("SET_MENU_VISIBLE", true);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", true);
    this.$store.dispatch("SET_PAGE_NAME", "생육데이터관리");
  },
  mounted() {
    if (this.$route.query.id) {
      this.growthId = this.$route.query.id;
      this.growthData = this.growthList.filter((item) => {
        if (item._id == this.growthId) return item;
      });
    }
  },
  computed: {
    ...mapState(["growthList", "growthTotal"]),
  },
  methods: {
    handleMove() {
      this.$router.push({
        name: "growthRegister",
        query: { id: this.growthId },
      });
    },
    handleDelete() {
      this.$confirm("삭제하시겠습니까?", {
        confirmButtonText: "확인",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        deleteGrowth(this.growthId).then((res) => {
          if (res.data.status == 200) {
            this.$router.replace("/growthList");
          } else {
            this.openAlert(res.data.message);
          }
        });
      });
    },

    removeGrowth() {
      deleteGrowth(this.farmId).then((res) => {
        if (res.data.status == 200) {
          this.createdAt = res.data.data.createdAt;
        }
      });
    },
    submit() {
      this.$router.push("/growthList");
    },
  },
};
</script>
